import React, { useState } from 'react';
import { firebase, auth } from './firebase';
import MuiPhoneNumber from 'material-ui-phone-number'; //https://www.npmjs.com/package/material-ui-phone-number
import Button from '@mui/material/Button'; // https://mui.com/components/buttons/ 
import ReactLogo from './assets/GuardianMED-horizontal-vector.svg';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

const Login = () => {
    // Inputs
    const [mynumber, setnumber] = useState("");
    const [otp, setotp] = useState('');
    const [show, setshow] = useState(false);
    const [final, setfinal] = useState('');

    const db = firebase.firestore();

    // Sent OTP
    const signin = () => {

        if (mynumber === "" || mynumber.length < 10) return;

        let verify = new firebase.auth.RecaptchaVerifier('recaptcha-container');
        auth.signInWithPhoneNumber(mynumber.phone, verify).then((result) => {
            setfinal(result);
            alert("code sent")
            setshow(true);
        })
            .catch((err) => {
                alert(err);
                window.location.reload()
            });
    }

    // Validate OTP
    const ValidateOtp = () => {
        if (otp === null || final === null)
            return;
        final.confirm(otp).then((result) => {
            // success

            const user = result.user;
            var userObject = {
              UID: user.uid,
              Phone: mynumber.phone,
              Device: mynumber.phone,
         //     DeviceYear: Device.deviceYearClass,
              lastlogin: new Date(),
           //   pushToken: pushtoken,
            }
            db.collection('users').doc(user.uid).set(userObject, { merge: true })
              .then(db.collection('phone').doc(mynumber.phone).set({
                UID: user.uid,
                Phone: mynumber.phone,
                Device: mynumber.phone,
           //     DeviceYear: Device.deviceYearClass,
              //  pushToken: pushtoken,
              }, { merge: true }))
    
            // let toast = Toast.show('Signed in 👍', {
            //   duration: Toast.durations.SHORT,
            //   position: Toast.positions.TOP,
            //   backgroundColor: 'green',
            //   shadow: true,
            //   animation: true,
            //   hideOnPress: true,
            // }
           // );
    
       //     console.log('Finished logging in user....');
         // });

        
        }).catch((err) => {
            alert("Wrong code");
        })
    }

    function handleOnChange(value) {
        var phone1 = value.replace('(', '-');
        var phone2 = phone1.replace(')', '-');
        setnumber({
            phone: phone2.replace(' ', '')
        });
    }

    return (
        <div style={{ "marginTop": "200px" }}>
            <center>
                <div>
                    <img style={{ maxWidth: 300, marginBottom: 30 }} src={ReactLogo} alt="React Logo" />

                </div>

                <div style={{ display: !show ? "block" : "none" }}>
                    <MuiPhoneNumber
                        defaultCountry={'us'}
                        onChange={handleOnChange}
                    />

                    <br /><br />
                    <div id="recaptcha-container"></div>
                    <Button

                        onClick={() => {
                            signin();
                        }
                        }>Get SMS Code</Button>
                    <div style={{ marginTop: 30 }}></div>
                    <Typography variant="body2" gutterBottom>
                        You must have a Guardian Clinical <br />provider account to access this portal.<br />
                    </Typography>

                </div>
                <div style={{ display: show ? "block" : "none" }}>

                    <TextField
                        required
                        id="outlined-required"
                        label="Enter your SMS Code"
                        defaultValue=""
                        onChange={(e) => { setotp(e.target.value) }}
                    />
                    <br /><br />
                    <Button onClick={ValidateOtp}>Verify</Button>
                </div>
            </center>
        </div>
    );
}

export default Login;
