import React, { useState, useEffect, Item } from 'react';
import { firebase, auth } from './firebase';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExport,

    gridClasses,
} from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button'; // https://mui.com/components/buttons/ 
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from '@mui/system';
import Toolbar from '@mui/material/Toolbar';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { format } from 'date-fns'; //https://www.npmjs.com/package/date-fns
import Modal from '@mui/material/Modal';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import TextField from '@mui/material/TextField';
import MessageIcon from '@mui/icons-material/Message';
//import List from '@mui/material/List';
//import Divider from '@mui/material/Divider';
//import ListItem from '@mui/material/ListItem';
//import ListItemIcon from '@mui/material/ListItemIcon';
//import ListItemText from '@mui/material/ListItemText';
//import InboxIcon from '@mui/icons-material/MoveToInbox';
//import MailIcon from '@mui/icons-material/Mail';
//import PracticeIcon from '@mui/icons-material/LocalHospital';
import MuiPhoneNumber from 'material-ui-phone-number'; //https://www.npmjs.com/package/material-ui-phone-number
//import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
//import { darken, lighten } from '@mui/material/styles';
import {
    XAxis, YAxis, Tooltip, CartesianGrid, Legend,
    Label, LabelList, Bar, BarChart, Cell
} from 'recharts'; //https://github.com/recharts/recharts/blob/master/demo/component/LineChart.tsx 
// https://recharts.org/en-US/api/LabelList
import InputLabel from '@mui/material/InputLabel';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import { Divider, Input } from '@mui/material';
import Badge from '@mui/material/Badge';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';



var x=0;

const db = firebase.firestore();
const Mainpage = () => {

    console.log(x += 1);
    const currentUser = firebase.auth().currentUser;
    const [scanCount, setscanCount] = useState(30);
    const [mynumber, setnumber] = useState("");
    const [founddevice, setfounddevice] = useState("");
    const [founddevicetext, setfounddevicetext] = useState("");
    const [loading, setloading] = useState(true);
    const [follow, setfollow] = useState([]);
    const [followUID, setfollowUID] = useState([]);
    const [target, settarget] = useState([]);
    const [userUID, setuserUID] = useState();
    //const [pendinguserUID, setpendinguserUID] = useState();
    const [userfull, setuserfull] = useState([]);
    const [pending, setpending] = useState([]);
    const [medID, setmedID] = useState();
    const [scandata, setscandata] = useState([]);
    const [scanArray, setscanArray] = useState([]);
    //   const [scoreArray, setscoreArray] = useState([]);
    //const [scoreValues, setscoreValues] = useState([]);
    const [meds, setmeds] = useState([]);
    //  const [groups, setgroups] = useState([]);
    //    const [selectedgroup, setselectedgroup] = useState();
    //const [selectedgroupname, setselectedgroupname] = useState();
    const [select, setSelection] = useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [updatename, setupdatename] = useState();
    const open = Boolean(anchorEl);
    const handleClick = (event) => { setAnchorEl(event.currentTarget); };   // MENU OPEN
    const handleClose = () => { setAnchorEl(null); };                       // MENU CLOSE
    const [Userdata, setUserdata] = useState([]);
    const [selecteduser, setselecteduser] = useState([]);
    const [addFollowopen, setaddFollowopen] = useState(false);
    const [orgname, setorgname] = useState();

    // PUSH MESSAGES 
    const [pushModalOpen, setpushModalOpen] = useState(false);
    const [pushToken, setpushToken] = useState([]);
    const [pushMessage, setpushMessage] = useState('');
    const handlepushMessageChange = (event) => { setpushMessage(event.target.value) };
    const handlepushMessageTemplateChange = (event) => { setpushMessage(event.target.value) };
    const pushModalHandleClose = () => setpushModalOpen(false);





    const addFollowhandleOpen = () => setaddFollowopen(true);
    const addFollowhandleClose = () => setaddFollowopen(false);

    const [tabvalue, settabValue] = useState(0);

    const tabhandleChange = (event, newValue) => {
        settabValue(newValue);
    };

    const handleChangeScanCount = (event) => {
        setscanCount(event.target.value);
    };

    const handleorgnameChange = (event) => {
        setorgname(event.target.value);
    };


    const logout = () => { auth.signOut(); }     // LOGOUT
    const [state, setState] = React.useState({
        //   top: false,
        left: false,
        //bottom: false,
        //right: false,
    });


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const handleOnChangePhone = (event) => {
        var phone1 = event.target.value.replace('(', '');
        phone1 = phone1.replace('-', '');
        phone1 = phone1.replace(')', '');
        phone1 = phone1.replace(' ', '');
        phone1 = phone1.replace(' ', '');
        setnumber({ phone: phone1.toString() });
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    // PRACTICE GROUP OPTION
    // const list = (anchor) => (
    //     <Box
    //         sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
    //         role="presentation"
    //         onClick={toggleDrawer(anchor, false)}
    //         onKeyDown={toggleDrawer(anchor, false)}         >
    //         <List>
    //             {groups.map((text, index) => (
    //                 <ListItem button key={text}
    //                     key={text.id}
    //                     onClick={() => {
    //                         //                   console.log('Practice Group Set to: ' + text.id);
    //                         setselectedgroup(text.id);
    //                         setselectedgroupname(text.name);
    //                     }
    //                     }
    //                 >
    //                     <ListItemIcon>
    //                         <PracticeIcon />
    //                     </ListItemIcon>
    //                     <ListItemText primary={text.name} secondary={text.adminName} />
    //                 </ListItem>
    //             ))}
    //         </List>
    //     </Box>
    // );

    db.collection('plans')
        .where('active', '==', true)
        .get()
        .then(function (querySnapshot) {
            querySnapshot.forEach(async function (doc) {
                //       console.log(doc.id, ' => ', doc.data());
                const priceSnap = await doc.ref.collection('prices').get();
                priceSnap.docs.forEach((doc) => {
                    // console.log(doc.id, ' => ', doc.data());
                });
            });
        });

    // MONITORING THESE USERS
    useEffect(() => {
        setloading(true);
        const unsubscribe = db.collection("users").doc(currentUser.uid).collection('follow').limit(100).onSnapshot((querySnapshot) => {
            //     const unsubscribe = db.collection("users").limit(100).onSnapshot((querySnapshot) => {
            const tempDoc = [];
            const userUID = [];
            querySnapshot.forEach((doc) => {
                tempDoc.push({ id: doc.id, ...doc.data() });
                userUID.push(doc.id);
                //      console.log('Follow User UID: ' + doc.id);
            });
            setfollow(tempDoc);
            setfollowUID(userUID);
            //          console.log('FollowUID array: ' + JSON.stringify(userUID));
        }, () => {
            console.log('Error in Allowed Users Snapshot to Firestore: ')
        });
        setloading(false);
        return () => unsubscribe()
    }, [currentUser]);


    // MONITORING USER SCORES
    useEffect(() => {
        setloading(true);
        if (followUID.length > 0) {
            const unsubscribe = db.collection("users").where(firebase.firestore.FieldPath.documentId(), 'in', followUID).onSnapshot((querySnapshot) => {
                //     const unsubscribe = db.collection("users").limit(100).onSnapshot((querySnapshot) => {
                const tempDoc = [];
                querySnapshot.forEach((doc) => {
                    tempDoc.push({ id: doc.id, userId: doc.id, ...doc.data() });
                });
                //setfollow(tempDoc);
                //       console.log('Status array: ' + JSON.stringify(tempDoc));
                //        console.log(mergeArrayObjects(tempDoc,follow));
                var tempArray = mergeArrayObjects(follow, tempDoc).slice().sort((a, b) => a.score - b.score);
                setuserfull(tempArray);
                //setuserfull(tempDoc.sort((a, b) => a.score - b.score));




            }, (err) => {
                console.log('Error in Allowed Users Snapshot to Firestore: ' + err.message
                    + ' where followUID: ' + JSON.stringify(followUID));
            });

            setloading(false);
            return () => unsubscribe()
        }
    }, [followUID, follow]);


    function mergeArrayObjects(arr1, arr2) {
        return arr1.map((item, i) => {
            if (item.id === arr2[i].id) {
                //merging two objects

                return Object.assign({}, item, arr2[i])
            }
        })
    }



    // CURRENT USER INFO
    useEffect(() => {
        setloading(true);
        const unsubscribe = db.collection("users").doc(currentUser.uid).onSnapshot((doc) => {
            setUserdata(doc.data());
            //console.log('Loading my user info');
            setloading(false);
        }, (error) => {
            console.log('Error in User (sharing) Listener to Firestore: ' + error)
        });
        setloading(false);
        return () => unsubscribe()
    }, [currentUser]);


    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <div>{children}</div>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }


    // useEffect(() => {
    //     setloading(true);
    //     const unsubscribe = db.collection("practiceGroup").doc(selectedgroup).collection('patients').limit(100).onSnapshot((querySnapshot) => {
    //         const tempDoc = [];
    //         querySnapshot.forEach((doc) => {
    //             tempDoc.push({ id: doc.id, ...doc.data() });
    //         });
    //         setfollow(tempDoc);
    //     }, (e) => {
    //         console.log('Error in Allowed Users Snapshot to Firestore: ' + e)
    //     });
    //     setloading(false);
    //     return () => unsubscribe()
    // }, [currentUser, selectedgroup, updatename]);



    // PRACTICE GROUP
    // useEffect(() => {
    //     setloading(true);
    //     const unsubscribe = db.collection("practiceGroup").where("members", "array-contains", currentUser.uid).onSnapshot((querySnapshot) => {
    //         const tempDoc = [];
    //         querySnapshot.forEach((doc) => {
    //             tempDoc.push({ id: doc.id, ...doc.data() });
    //         });
    //         setgroups(tempDoc);
    //         // console.log('Practice Groups: ' + JSON.stringify(tempDoc));
    //     }, () => {
    //         console.log('Error in Allowed Users Snapshot to Firestore: ')
    //     });
    //     setloading(false);
    //     return () => unsubscribe()
    // }, [currentUser]);


    // MEDICATION SCANS ------------------------------------
    // useEffect(() => {
    //     setloading(true);
    //     //if (userUID != undefined && userUID != null && userUID != '') {
    //         if (userUID) {
    //             console.log('Querying with medKey: ' + medID + ' on userUID: ' + userUID);
    //         // var  queryFirestore = db.collection("users").doc(userUID).collection('scans').where("medKey", "==", medID);
    //         if (medID) {

    //             var queryFirestore = db.collection("users").doc(userUID).collection('scans').where("medKey", "==", medID);
    //         }
    //         else {
    //             console.log('Querying ELSE as medKey: ' + medID);
    //             var queryFirestore = db.collection("users").doc(userUID).collection('scans');
    //         }


    //         const unsubscribe = queryFirestore.orderBy("scannedTimestamp", "desc").limit(scanCount).get().then((querySnapshot) => {
    //             //const unsubscribe = queryFirestore.orderBy("scannedTimestamp", "desc").limit(20).onSnapshot((querySnapshot) => {
    //             const tempDoc = [];
    //             const tempScore = [];
    //             var x = 0;

    //             querySnapshot.forEach((doc) => {
    //                 try {
    //                     tempDoc.push({ id: doc.id, ...doc.data() });
    //                     tempScore.push(doc.data().score);
    //                     setscandata(tempDoc);
    //                     x += 1;
    //                     //   console.log('Setscandata executed ' + x  + ' times.');
    //                 }
    //                 catch
    //                 { }
    //             });
    //             //  if (scandata != undefined)  { console.log('Most Recent Dose: ' + scandata[0].notes)}

    //             //setscoreArray(tempScore);

    //             setloading(false);
    //             return () => unsubscribe()
    //         }, () => {
    //             console.log('Error in MedLog Listener to Firestore: ')
    //         });
    //         //    return () => unsubscribe()
    //     }
    // }, [userUID, medID, scanCount]);


    // LOAD SCAN ARRAY BASED ON currentUser.uid
    useEffect(() => {
        setloading(true);
        if (userUID) {
            const unsubscribe = db.collection("scans").doc(userUID).onSnapshot((doc) => {
                //setscanArray(doc.data());
                var tempArray = [];
                tempArray = doc.data().scanarray.slice().sort((a, b) => b.scannedTimestamp.seconds - a.scannedTimestamp.seconds);
                var newArray = tempArray.slice(0, scanCount);
                if (medID) {
                    setscandata(newArray.filter(e => e.medKey === medID));
                }
                else {
                    setscandata(newArray);
                }

                //  setscandata(tempArray.slice(0,scanCount));

                setloading(false);
            }, (error) => {
                console.log('Error in User (sharing) Listener to Firestore: ' + error)
            });

            setloading(false);
            return () => unsubscribe()
        }
    }, [userUID, medID]);



    useEffect(() => {
        setloading(true);
        //if (userUID !== undefined && userUID !== null && userUID !== '') {
        if (userUID) {
            //       console.log("UID: " + userUID);
            const unsubscribe = db.collection("users").doc(userUID).collection('meds').orderBy('nextDoseTimestamp').onSnapshot((querySnapshot) => {
                const tempDoc = [];
                querySnapshot.forEach((doc) => {
                    tempDoc.push({ id: doc.id, ...doc.data() });
                });
                setmeds(tempDoc);
                setloading(false);
                return () => unsubscribe()
            }, () => {
                console.log('Error in Snapshot of Med collection in Firestore: ')
            });
        }
        setloading(false);
    }, [userUID]);

    useEffect(() => {
        setloading(true);
        if (userUID) {
            //       console.log("UID: " + userUID);
            const unsubscribe = db.collection("users").doc(currentUser.uid).collection('follow').doc(userUID).onSnapshot((doc) => {

                setselecteduser(doc.data());
                //     console.log('Selected user: ' + JSON.stringify(doc.data()));
                setloading(false);
                return () => unsubscribe()
            }, () => {
                console.log('Error in Snapshot of Active User from follow collection in Firestore: ')
            });
        }
        setloading(false);
    }, [userUID]);


    // PENDING REQUESTS TO FOLLOW
    useEffect(() => {
        setloading(true);
        //  const unsubscribe = db.collection("pending").where("Users", "array-contains", currentUser.uid).limit(100).onSnapshot((querySnapshot) => {
        const unsubscribe = db.collection("pending").where("RequestorUID", "==", currentUser.uid).onSnapshot((querySnapshot) => {
            const tempDoc = [];
            querySnapshot.forEach((doc) => {
                tempDoc.push({ id: doc.id, ...doc.data() });

            });
             
            setpending(tempDoc);
        }, (error) => {
            console.log('Error in pending Users Snapshot to Firestore: ' + error)
        });
        setloading(false);
        return () => unsubscribe()
    }, [currentUser]);


    const handleRowSelection = (e) => {
        setSelection(e.toString());
        setuserUID(e.toString());

        //console.log("Row selected: " + e.toString());
    }
    const handleCellClick = (param, event) => {
        //     console.log(param);
        //  console.log(event);
        if (param.colIndex === 2) {
            event.stopPropagation();
        }
    };

    useEffect(() => {
        if (select) {
            setloading(true);
            //         console.log('Selected Row: ' + select); // <-- The state is updated
            setloading(false);
        }
    }, [select]);


    function showSpinner() {
        return (
            <Box sx={style}>
                <div style={{ flexDirection: 'row' }}>
                    <Typography id="modal-modal-title" variant="h5" component="h2">
                        <CircularProgress size={80} /> Loading...
                    </Typography>
                </div>
            </Box>
        )

    }

    // SAVE PATIENT NAME
    function savePatientName(params) {
        //      console.log('Saved Patient Name: ' + params.value + ' row id: ' + select);
        setupdatename(params.value);
        //const docRef = db.collection("practiceGroup").doc(selectedgroup).collection('patients').doc(select);
        const docRef = db.collection("users").doc(currentUser.uid).collection('follow').doc(select);
        docRef.set({
            patientName: params.value
        }, { merge: true });
        return { ...params.row, updatename };

    }
    // USER ROWS AND COLUMNS
    const userrows = userfull;
    const usercolumns = [
        {
            field: 'id', headerName: 'Details', width: 90,
            renderCell: (params) => (
                <strong>
                    <Button
                        size="small"
                        variant='outlined'
                        onClick={() => {
                            setuserUID(params.value);
                            settabValue(1);
                        }}
                    >
                        Open
                    </Button>
                </strong>
            ),
        },

        {
            field: 'pushToken', headerName: 'Msg', width: 60,
            renderCell: (params) => (
                <strong>
                    <Button
                        size="small"

                        onClick={() => {
                            console.log('UID to Push Msg: ' + params.value);
                            setpushToken(params.row);
                            console.log(JSON.stringify(params.row));
                            setpushModalOpen(true);

                        }}
                    >
                        <MessageIcon />
                    </Button>
                </strong>
            ),
        },
        {
            field: 'score', headerName: 'Score', type: 'number', width: 85,
            valueFormatter: ({ value }) => {
                if (value) {
                    const scoreVal = value.toFixed(2);
                    return `${scoreVal}%`;
                } else { return 'No data' }
            }
        },
        {
            field: 'streak', headerName: 'Consec.', type: 'number', width: 85,
            valueFormatter: ({ value }) => {
                if (value) {

                    if (value > 0) { return `${value}🔥` }
                    else { return `${value}` }

                } else { return 'No data' }
            }
        },

        { field: 'Device', headerName: 'Device', width: 200, },

        {
            field: 'patientName', headerName: 'Patient', width: 150, editable: true,
            valueSetter: savePatientName,
        },
        { field: 'Phone', headerName: 'Phone', width: 150 },
        //    { field: 'requested', headerName: 'Authorized', type: 'date', width: 110, valueFormatter: ({ value }) => new Date(value.seconds * 1000).toLocaleDateString() },

    ];
    //MEDICATION ROWS AND COLUMNS
    const medrows = meds;
    const medcolumns = [
        {
            field: 'id', headerName: 'Details', width: 80,
            renderCell: (params) => (
                <strong>
                    <Button
                        size="small"
                        variant='outlined'
                        onClick={() => {
                            setmedID(params.value);
                            //       console.log('medID: ' + params.value);
                            settabValue(2);
                        }}
                    >
                        Scans
                    </Button>
                </strong>
            ),
        },
        { field: 'medication', headerName: 'Medication', width: 150, },
        {
            field: 'score', headerName: 'Score', type: 'number', width: 85,
            valueFormatter: ({ value }) => {
                if (value) {
                    const scoreVal = value.toFixed(2);
                    return `${scoreVal}%`;
                } else { return 'No data' }
            }
        },
        { field: 'scans', headerName: 'Scans', type: 'number', width: 75, },
        { field: 'skips', headerName: 'Skips', type: 'number', width: 75, },
        { field: 'streak', headerName: 'Consec.', type: 'number', width: 90, },
        { field: 'frequency', headerName: 'Freq.', type: 'number', width: 75, },
        {
            field: 'scannedTimestamp', headerName: 'Last Scan', type: 'datetime', width: 150,
            valueFormatter: ({ value }) => {
                if (value) {
                    const lastScan = format(new Date(value.seconds * 1000), 'MM-dd-yy h:mm bbb');
                    return `${lastScan}`;
                } else { return 'No data' }
            }
        },
        {
            field: 'nextDoseTimestamp', headerName: 'Next Dose', type: 'datetime', width: 150,
            valueFormatter: ({ value }) => format(new Date(value.seconds * 1000), 'MM-dd-yy h:mm bbb')
        },

        {
            field: 'nextDeadline', headerName: 'Dose Deadline', type: 'datetime', width: 150,
            valueFormatter: ({ value }) => {
                if (value) {
                    const lastScan = format(new Date(value.seconds * 1000), 'MM-dd-yy h:mm bbb');
                    return `${lastScan}`;
                } else { return 'No data' }
            }
        },

    ]
    // SCAN ROWS AND COLUMNS
    const scanrows = scandata;
    const scancolumns = [
        {
            field: 'scanId', headerName: 'Scan', width: 80,
            renderCell: (params) => (
                <strong>
                    <Button
                        size="small"
                        variant='outlined'
                        onClick={() => {
                            //setmedID(params.value);
                            console.log('ScanId: ' + params.value);
                            //settabValue(2);
                        }}
                    >
                        Detail
                    </Button>
                </strong>
            ),
        },
        { field: 'medication', headerName: 'Medication', width: 150, },
        {
            field: 'score', headerName: 'Score', width: 85, type: 'number',
            valueFormatter: ({ value }) => {
                if (value) {
                    const scoreVal = value.toFixed(2);
                    return `${scoreVal}%`;
                } else { return 'No data' }
            }
        },
        { field: 'scanCount', headerName: 'Scans', type: 'number', width: 75, },
        { field: 'skipCount', headerName: 'Skips', type: 'number', width: 75, },
        { field: 'doseStatus', headerName: 'Status', width: 75, },
        {
            field: 'scannedTimestamp', headerName: 'Scanned', type: 'datetime', width: 150,
            valueFormatter: ({ value }) => {
                if (value) {
                    const lastScan = format(new Date(value.seconds * 1000), 'MM-dd-yy h:mm bbb');
                    return `${lastScan}`;
                } else { return 'No data' }
            }
        },
        {
            field: 'nextDoseTimestamp', headerName: 'Next Dose', type: 'datetime', width: 150,
            valueFormatter: ({ value }) => format(new Date(value.seconds * 1000), 'MM-dd-yy h:mm bbb')
        },

        { field: 'notes', headerName: 'Details', width: 800, },
    ]



    // PENDING REQUESTS ROWS AND COLUMNS
    const pendingrows = pending;
    const pendingcolumns = [
        {
            field: 'id', headerName: 'Action', width: 90,
            renderCell: (params) => (
                <strong>

                    <Button
                        size="small"
                        color="error"
                        onClick={() => {
                            console.log('Index value selected: ' + params.value);
                            // setpendinguserUID(params.value);
                            if (window.confirm("Are you sure you want to cancel this access request?")) {
                                db.collection("pending").doc(params.value).delete();
                            }
                            //settabValue(1);
                        }}
                    >
                        Cancel
                    </Button>
                </strong>
            ),
        },
        { field: 'AcceptorDevice', headerName: 'Patient', width: 200, },
        { field: 'AcceptorPhone', headerName: 'Phone', width: 100, },
        {
            field: 'AcceptorPhone', headerName: 'Resend', width: 300,
            renderCell: (params) => (
                <div>
                    <Button
                        style={{ marginLeft: 10 }}
                        size="small"
                        variant="outlined"
                        onClick={() => {
                            // setpendinguserUID(params.value);
                            if (window.confirm("Are you sure you want to RESEND this access request?")) {
                                var pendingrequestId = params.id;
                                //  db.collection("pending").doc(params.value).delete();
                                console.log('Resend request');
                                console.log('Request to follow pressed for ' + params.value);
                                db.collection("phone").doc(params.value).get().then((doc) => {
                                    if (doc.exists) {
                                        console.log("User Exists!  Document data:", doc.data());
                                        //var target = doc.data();
                                        //       setfounddevice(true);
                                        settarget(doc.data());
                                        var targetName = doc.data().friendlyName ? doc.data().friendlyName : doc.data().Device;
                                        //        setfounddevicetext('Found ' + targetName + ', request access?');
                                        console.log('Pending Request ID: ' + pendingrequestId);
                                    } else {
                                        // doc.data() will be undefined in this case
                                        console.log("No such document!");
                                        //     setfounddevice(false);
                                        //    setfounddevicetext('Could not find a Guardian Clinical user for ' + params.value)

                                    }
                                }).catch((error) => {
                                    console.log("Error getting document:", error);
                                });

                                var pendingRequestObject = {
                                    RequestorDevice: Userdata.friendlyName ? Userdata.friendlyName : Userdata.Device,
                                    RequestorPhone: Userdata.Phone,
                                    RequestorUID: Userdata.UID,
                                    RequestorpushToken: Userdata.pushToken,
                                    AcceptorDevice: target.friendlyName ? target.friendlyName : target.Device,
                                    AcceptorPhone: target.Phone,
                                    AcceptorUID: target.UID,
                                    AcceptorpushToken: target.pushToken,
                                    RequestDateTime: new Date(),
                                    Users: [currentUser.uid, target.UID],
                                    TextMessage: Userdata.friendlyName ? Userdata.friendlyName + ' wants to access your Guardian Clinical records.' : Userdata.Device + ' wants to access your Guardian Clinical records.'
                                };
                                // PUT INTO followRequests COLLECTION UNDER REQUESTING USER
                                //                                var followRequestUID = db.collection("users").doc(currentUser.uid).collection('followRequests').doc(target.UID);
                                //                              followRequestUID.set({ requested: new Date() });

                                //   db.collection("push").add(pendingRequestObject);
                                console.log('Updating request time for access for pendingrequestId: ' + pendingrequestId);
                                db.collection("pending").doc(pendingrequestId).update({ RequestDateTime: new Date() });
                                setfounddevicetext('');
                                setfounddevice(false);
                            }

                            //settabValue(1);
                        }}
                    >
                        Resend to {params.value}
                    </Button>
                </div>
            ),


        },
        { field: 'RequestDateTime', headerName: 'Requested', width: 110, valueFormatter: ({ value }) => new Date(value.seconds * 1000).toLocaleDateString() },


    ];

    //TOOLBAR FOR EXPORTING DATA
    const CustomToolbar = (val) => {
        var titleText = val;
        //    console.log('CustomToolbar value: ' + JSON.stringify(val));
        return (
            <GridToolbarContainer className={gridClasses.toolbarContainer} >

                <GridToolbarExport />

            </GridToolbarContainer>
        );
    }

    const CustomToolbarLog = (val) => {
        var titleText = val;
        //    console.log('CustomToolbar value: ' + JSON.stringify(val));
        return (
            <GridToolbarContainer className={gridClasses.toolbarContainer} >
                <Grid container
                    direction="row"
                    justifyContent="space-between"

                    alignItems="flex-start">

                    <GridToolbarExport />


                    <FormControl variant="standard" sx={{ m: 1, minWidth: 60 }}>
                        <InputLabel id="scan-count-label">Records</InputLabel>
                        <Select
                            labelId="scan-count-label"
                            id="demo-simple-select"
                            value={scanCount}
                            label="Scans"
                            onChange={handleChangeScanCount}
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={30}>30</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                        </Select>
                    </FormControl>

                </Grid>

            </GridToolbarContainer >
        );
    }

    const chartDate = (val) => {
        //    console.log('Chartdate called: with payload: ' + val.scannedTimestamp.seconds);
        //   return (format(new Date(val.scannedTimestamp.seconds * 1000), 'MM-dd-yy h:mm bbb'))
        return (format(new Date(val.scannedTimestamp.seconds * 1000), 'MM-dd-yy'))
    }

    const formatMedLabel = (val) => {
        //  console.log('Medication in  payload: ' + val.medication);
        //   return (format(new Date(val.scannedTimestamp.seconds * 1000), 'MM-dd-yy h:mm bbb'))
        return (val.medication)
    }

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            var doseData = payload[0].payload;
            // console.log('Payload: ' + JSON.stringify(doseData));
            //   console.log('Payload: ' + doseData.medication);
            return (
                <div style={{
                    backgroundColor: 'white', borderStyle: 'solid',
                    borderRadius: 6,
                    padding: 5,
                    borderColor: 'gray',
                    borderWidth: 1
                }} className="custom-tooltip">
                    <b>{doseData.medication}</b><br />
                    {format(new Date(doseData.scannedTimestamp.seconds * 1000), 'MMMM dd yyyy h:mm bbb')}<br />
                    Timeliness: {doseData.doseStatus}<br />
                    Score: {doseData.score.toFixed(2)}%<br />
                    Notes: {doseData.notes}<br />
                    Next Dose: {format(new Date(doseData.nextDoseTimestamp.seconds * 1000), 'MMMM dd yyyy h:mm bbb')}<br />
                </div>
            );
        }
        return null;
    };

    const doseDetail = (val) => {
        //    console.log('Chartdate called: with payload: ' + val.scannedTimestamp.seconds);
        //   return (format(new Date(val.scannedTimestamp.seconds * 1000), 'MM-dd-yy h:mm bbb'))
        console.log('Dose ID: ' + val.scanId);
        //return (format(new Date(val.scannedTimestamp.seconds * 1000), 'MM-dd-yy'))
    }


    return (
        
        <div>

            <div>
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static">
                        <Toolbar>
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 2 }}
                                onClick={toggleDrawer('left', true)}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                Guardian Portal™
                            </Typography>
                            <Button color="inherit"
                                onClick={toggleDrawer('left', true)}
                            >   {Userdata.friendlyName ? Userdata.friendlyName : Userdata.Device}</Button>
                        </Toolbar>
                    </AppBar>
                </Box>
            </div>

            {pushToken.score ?
            <div>
                <Dialog
                    open={pushModalOpen}
                    onClose={() => {
                        setpushToken([]);
                        setpushMessage('');
                        pushModalHandleClose();
                    }}
                >
                    <DialogTitle>Send Push Message to {pushToken.patientName ? pushToken.patientName : pushToken.Device}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            This will send a notification to the user's phone {pushToken.Phone}.
                            <Card style={{ margin: 10 }}
                            >
                                <CardContent>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        Message Preview:
                                    </Typography>
                                    <Typography style={{ padding: 10 }}>
                                        {pushMessage}
                                    </Typography>

                                </CardContent>


                            </Card>


                        </DialogContentText>
                        <FormControl fullWidth>
                            <Input id="standard-basic"
                                onBlur={handlepushMessageChange}
                                autoFocus
                                multiline
                                maxRows={3}
                              type='text'
                              inputProps={{ maxLength: 178 }}
                                //defaultValue={pushMessage}
                                fullWidth
                                label="Message" variant="standard" />
                        </FormControl>

                        <FormControl style={{ marginTop: 20 }} fullWidth>
                            <InputLabel id="demo-simple-select-label">Select a template message</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Select a template message."
                                onChange={handlepushMessageTemplateChange}
                            >
                                <MenuItem value={'Great job on your ' + pushToken.score.toFixed(2) + '% adherence score!'}>Good score ({pushToken.score.toFixed(2)}%)</MenuItem>
                                <MenuItem value={'We can improve your ' + pushToken.score.toFixed(2) + '% adherence score.'}>Improve score ({pushToken.score.toFixed(2)}%)</MenuItem>
                                <MenuItem value={'You have had ' + pushToken.streak + ' consecutive on-time doses.'}>Streak (consecutive on-time doses) ({pushToken.streak})</MenuItem>
                                <MenuItem value={'Do you need some help? Please call us.'}>Need help?</MenuItem>
                                <MenuItem value={'Just checking in on your medication adherence.'}>Checking in.</MenuItem>
                            </Select>
                        </FormControl>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={pushModalHandleClose}>Cancel</Button>
                        <Button onClick={() => {

                            var friendly = Userdata.friendlyName ? Userdata.friendlyName : Userdata.Device;
                            var fullmessage = friendly + ': ' + pushMessage;
                            var pendingRequestObject = {
                                RequestorDevice: Userdata.friendlyName ? Userdata.friendlyName : Userdata.Device,
                                RequestorPhone: Userdata.Phone,
                                RequestorUID: Userdata.UID,
                                RequestorpushToken: Userdata.pushToken,
                                AcceptorpushToken: pushToken.pushToken,
                                RequestDateTime: new Date(),
                                TextMessage: fullmessage
                            };
                            db.collection("push").add(pendingRequestObject);
                            pushModalHandleClose();
                            setpushToken([]);

                        }}>Send</Button>
                    </DialogActions>
                </Dialog >

            </div>
            : <div></div>}
            <Modal
                open={addFollowopen}
                onClose={() => {
                    setfounddevicetext('');
                    setfounddevice(false);
                    addFollowhandleClose();
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h5" component="div">
                        Add New Patient
                    </Typography>
                    <Typography id="modal-modal-description" component="div" sx={{ mt: 2 }}>
                        Mobile number of patient to follow (must have Guardian Clinical App):

                    </Typography>
                    <MuiPhoneNumber
                        defaultCountry={'us'}
                        //onChange={handleOnChangePhone}
                        onBlur={handleOnChangePhone} />
                    <Button
                        onClick={() => {
                            console.log('Request to follow pressed for ' + mynumber.phone);
                            db.collection("phone").doc(mynumber.phone).get().then((doc) => {
                                if (doc.exists) {
                                    console.log("User Exists!  Document data:", doc.data());
                                    //var target = doc.data();
                                    setfounddevice(true);
                                    settarget(doc.data());
                                    var targetName = doc.data().friendlyName ? doc.data().friendlyName : doc.data().Device;
                                    setfounddevicetext('Found ' + targetName + ', request access?')
                                } else {
                                    // doc.data() will be undefined in this case
                                    console.log("No such document!");
                                    setfounddevice(false);
                                    setfounddevicetext('Could not find a Guardian Clinical user for ' + mynumber.phone)

                                }
                            }).catch((error) => {
                                console.log("Error getting document:", error);
                            });
                        }}

                    >Verify Number</Button>
                    {founddevicetext.length > 0 ?
                        <div>
                            {founddevice ?
                                <Chip label={founddevicetext} color="success"
                                    onClick={() => {
                                        console.log('Requesting access to ' + target.Device);

                                        var pendingrequestId;
                                        //          var pendingacceptId;

                                        var pendingRequestObject = {
                                            RequestorDevice: Userdata.friendlyName ? Userdata.friendlyName : Userdata.Device,
                                            RequestorPhone: Userdata.Phone,
                                            RequestorUID: Userdata.UID,
                                            RequestorpushToken: Userdata.pushToken,
                                            AcceptorDevice: target.friendlyName ? target.friendlyName : target.Device,
                                            AcceptorPhone: target.Phone,
                                            AcceptorUID: target.UID,
                                            AcceptorpushToken: target.pushToken,
                                            RequestDateTime: new Date(),
                                            Users: [currentUser.uid, target.UID],
                                            TextMessage: Userdata.friendlyName ? Userdata.friendlyName + ' wants to access your Guardian Clinical records.' : Userdata.Device + ' wants to access your Guardian Clinical records.'
                                        };
                                        // PUT INTO followRequests COLLECTION UNDER REQUESTING USER

                                        console.log('Creating entry for followRequest using document name: ' + target.UID);

                                        //        var followRequestUID = db.collection("users").doc(currentUser.uid).collection('followRequests').doc(target.UID);

                                        //                                        followRequestUID.set({ requested: new Date() });
                                        console.log('Pending Request Object: ' + JSON.stringify(pendingRequestObject));
                                        db.collection("push").add(pendingRequestObject);
                                        db.collection("pending").add(pendingRequestObject)
                                            .then((docRef) => {
                                                pendingrequestId = docRef.id;
                                                console.log("pendingrequestId: ", docRef.id);
                                            }).
                                            then(() => {
                                                setfounddevicetext('');
                                                setfounddevice(false);
                                                addFollowhandleClose();
                                            })




                                    }}
                                />
                                :
                                <Chip label={founddevicetext} color="error" />
                            }
                        </div>
                        :
                        <div>

                        </div>}

                </Box>
            </Modal>


            <div>

                {/* <Button onClick={toggleDrawer('left', true)}>{'left'}</Button> */}
                <SwipeableDrawer
                    PaperProps={{
                        sx: { width: "45%" },
                    }}
                    anchor={'left'}
                    open={state['left']}
                    onClose={toggleDrawer('left', false)}
                    onOpen={toggleDrawer('left', true)}
                >
                    <Typography style={{ padding: 20 }} variant="h6" gutterBottom component="div">
                        {Userdata.friendlyName ? Userdata.friendlyName : Userdata.Device}
                    </Typography>
                    <Typography style={{ paddingLeft: 20 }} gutterBottom component="div">
                        <div>  <TextField id="standard-basic" label="Organization Name" variant="standard"
                            defaultValue={Userdata.friendlyName}
                            onChange={handleorgnameChange}
                            onBlur={() => {
                                //console.log('Update new value: ' + orgname);
                                if (orgname) {
                                    db.collection("users").doc(currentUser.uid).set({ friendlyName: orgname }, { merge: true });
                                }
                            }}
                        /></div>
                        <div style={{ marginTop: 20 }}>License: Standard</div>
                        <div>Device: {Userdata.Device}</div>
                        <div>Phone: {Userdata.Phone}</div>
                        <div style={{ padding: 20 }}>
                            <Button variant="outlined"
                                onClick={async () => {
                                    console.log('Subscribe Now');
                                    const docRef = await db.collection('customers').doc(currentUser.uid)
                                        .collection('checkout_sessions')
                                        .add({
                                            price: 'price_1KDvjlAsu9NqI0TRdE8NNv2v',
                                            success_url: window.location.origin,
                                            cancel_url: window.location.origin,
                                        });
                                    // Wait for the CheckoutSession to get attached by the extension
                                    docRef.onSnapshot((snap) => {
                                        const { error, url } = snap.data();
                                        if (error) {
                                            // Show an error to your customer and
                                            // inspect your Cloud Function logs in the Firebase console.
                                            alert(`An error occured: ${error.message}`);
                                        }
                                        if (url) {
                                            // We have a Stripe Checkout URL, let's redirect.
                                            window.location.assign(url);
                                        }
                                    });
                                }
                                }
                            >Subscribe</Button>

                        </div>
                        <Divider></Divider>
                        <div style={{ padding: 20 }}>      <Button variant="outlined" color="error" onClick={logout}>Logout</Button> </div>
                    </Typography>

                    {/* {list('left')} */}


                </SwipeableDrawer>

            </div>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabvalue} onChange={tabhandleChange} aria-label="basic tabs example">
                    <Tab label="Patients" {...a11yProps(0)} />
                    <Tab style={{ display: userUID ? 'block' : 'none' }} label="Medications" {...a11yProps(1)} />
                    <Tab style={{ display: userUID ? 'block' : 'none' }} label="Log" {...a11yProps(2)} />
                    <Tab style={{ display: userUID ? 'block' : 'none' }} label="Graphs" {...a11yProps(3)} />
                </Tabs>
            </Box>
            <TabPanel value={tabvalue} index={0}>
                <Grid container
                    direction="row"
                    justifyContent="space-between"

                    alignItems="flex-start">
                    <div>Patient List</div>
                    <div>  <Button variant="outlined" onClick={addFollowhandleOpen}>New Patient</Button></div>

                </Grid>




                <div style={{ marginTop: 10, padding: 20 }}>
                    <div style={{ height: 300, width: '100%' }}>
                        {!loading ?
                            <DataGrid
                                rowHeight={35}
                                rows={userrows}
                                columns={usercolumns}
                                onSelectionModelChange={handleRowSelection}
                                //  cellEditCommit={console.log('Edit Commited')}
                                cellEditCommit={handleCellClick}
                                cellEditStop={handleCellClick}
                                getRowClassName={(params) => `super-app-theme--${params.row.status}`}
                                components={{
                                    Toolbar: CustomToolbar,
                                }}

                            />

                            : showSpinner()}

                    </div>
                </div>

                Pending Requests
                <div style={{ marginTop: 10, padding: 20 }}>
                    <div style={{ height: 300, width: '100%' }}>
                        {!loading ?
                            <DataGrid
                                style={{ backgroundColor: '#f2f2f2' }}
                                rowHeight={35}
                                rows={pendingrows}
                                columns={pendingcolumns}
                            //    onSelectionModelChange={handleRowSelection}
                            //  cellEditCommit={console.log('Edit Commited')}
                            //   cellEditCommit={handleCellClick}
                            //  cellEditStop={handleCellClick}


                            />

                            : showSpinner()}

                    </div>
                </div>

            </TabPanel>
            <TabPanel value={tabvalue} index={1}>
                <div>
                    Patient: {selecteduser.patientName}
                </div>
                <div>
                    <Button
                        onClick={() => {
                            setmedID(null);
                            console.log('Clear med selection');
                            settabValue(2);

                        }}

                    >All Scans</Button>
                </div>

                <div style={{ marginTop: 10, padding: 20 }}>
                    <div style={{ height: 400, width: '100%' }}>

                        {!loading ?

                            <DataGrid rowHeight={35} rows={medrows} columns={medcolumns}
                                components={{
                                    Toolbar: CustomToolbar,
                                }}

                            />
                            : showSpinner()}
                    </div>
                </div>
            </TabPanel>
            <TabPanel value={tabvalue} index={2}>
                <div>
                    Patient: {selecteduser.patientName}
                </div>
                <div>
                    <Button
                        onClick={() => {
                            setmedID(null);
                            console.log('Clear med selection');

                        }}

                    >Show all medications</Button>
                </div>


                <div style={{ marginTop: 10, padding: 20 }}>
                    <div style={{ height: 400, width: '100%' }}>
                        {!loading ?
                            <DataGrid
                                getRowId={row => row.scannedTimestamp.seconds}
                                rowHeight={35}
                                rows={scanrows}
                                columns={scancolumns}
                                components={{
                                    Toolbar: CustomToolbarLog,
                                }}
                            />
                            : showSpinner()


                        }

                    </div>
                </div>
            </TabPanel>
            <TabPanel value={tabvalue} index={3}>
                Patient: {selecteduser.patientName}
                {!loading ?
                    <BarChart
                        width={800}
                        height={400}
                        data={scandata}
                        margin={{ top: 5, right: 20, left: 10, bottom: 45 }}

                    >
                        <XAxis
                            //  key="scannedTimestamp.seconds"
                            dataKey={chartDate} >
                            <Label value="Date"
                                offset={30}
                                position="bottom"
                                type='number'
                                LabelList='scannedTimestamp.seconds' />
                        </XAxis>
                        <YAxis>
                            <Label value="Adherence Score" angle={-90} position='insideLeft' />
                        </YAxis>

                        <Tooltip content={CustomTooltip} />
                        <CartesianGrid stroke="#f5f5f5" />
                        <Legend verticalAlign="top" height={36} />
                        <Bar key='id' dataKey="score"
                            //fill="#008ae6" 
                            fill='#008ae6'

                            fillOpacity={60}
                            onClick={doseDetail}
                        >
                            {scandata.map((entry, id) => (
                                <Cell key={id} fill={entry.doseStatus === "skipped" ? '#cc0000' : '#008ae6'}

                                />
                            ))}
                            <LabelList
                                key='id'
                                dataKey={formatMedLabel}
                                // onClick={console.log('Bar clicked')}

                                position="center"
                                angle="-90"
                                fontSize={14}
                                width={300}
                                fontWeight={'medium'}
                                fill='#ffffff'
                                fillOpacity={50}
                            />

                        </Bar>
                    </BarChart>
                    : showSpinner()}
            </TabPanel>



        </div>

    );
}

export default Mainpage;
