import React from 'react';
import { auth } from './firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from './login';
import Mainpage from './main';
//import UserDetail from './userDetail';



function App() {
  const [user] = useAuthState(auth);
  return (

    user ?
      <div>     
         {/* <TopNav /> */}
        <Router>
          <Routes>
            <Route path="/" element={<Mainpage />} />
            <Route path="/main" component={Mainpage} />
          
            <Route path="login" component={Login} />
          </Routes>
        </Router>
      </div>
      : <Login />

  );
}

export default App;
