import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyA1YSxyMOjyY_JaoZWPyb_kX-yv3iGDbJk",
    authDomain: "guardianscan99.firebaseapp.com",
    databaseURL: "https://guardianscan99-default-rtdb.firebaseio.com",
    projectId: "guardianscan99",
    storageBucket: "guardianscan99.appspot.com",
    messagingSenderId: "970766828802",
    appId: "1:970766828802:web:81c52d231d0d53af18ee1b",
    measurementId: "G-JEHS7HWFZV"
};

firebase.initializeApp(firebaseConfig);
var auth = firebase.auth();
export {auth , firebase};
